<template>
  <main>
      <headerElem></headerElem>
        <p>Notre opération débute le 20 Juin 2022</p>
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
export default {
  name: 'contact',
      components: {
    HeaderElem,
  },
}
</script>